.circular-progress {
    margin-left: 50%;
    margin-top: 25%;
}

@media only screen and (max-width: 600px) {
    .circular-progress {
        margin-left: 45%;
        margin-top: 75%;
    }
}