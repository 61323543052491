.Post {
    padding-top: 50px;
    padding-left: 20px;
}

.latest,
.all-post {
    display: flex;
    margin-left: 25px;
}

.all-post {
    margin-bottom: 50px;
}


.latest {
    margin-bottom: 30px;
}

h1 {
    margin-left: 25px;
}

.view-all {
    position: absolute;
    right: 35px;
    margin-top: -30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.view-all:hover {
    transform: scaleX(1.1);
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 350px;
    width: 280px;
    margin-right: 30px;
    margin-top: 15px;
    padding-top: 10px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}

/* Add rounded corners to the top left and the top right corner of the image */
.card .latest-img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 180px;
    object-fit: contain;
}

.card .item-sold {
    color: red;
    margin-left: 15px;
    font-weight: 700;
    font-size: 18px;
}


.card .height-90 {
    height: 160px;
}


.seller-date {
    margin-bottom: 15px;
    margin-top: 25px;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.no-latest-post{
    color: black;
}




/* Small Screen */
@media only screen and (max-width: 600px) {

    .Post {
        padding: 10px;
    }


    .latest,
    .all-post {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: 0px;
        margin-right: 5px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .all-post {
        margin-bottom: 15px;
    }

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
        width: 185px;
        height: auto;
        margin-bottom: 25px;
        margin-right: 15px;
        cursor: pointer;
    }

    .card .container {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
    }


    .card .container h2 {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .card .container h4 {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .card .container p {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .image-container .latest-img {
        border-radius: 5px 5px 0 0;
        width: 185px;
        height: 110px;
        object-fit: contain;
    }

    .image-container .height-90 {
        height: 90px;
    }

    .image-container .item-sold {
        color: red;
        margin-left: 60px;
        font-size: 14px;
    }

    .card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .view-all {
        right: 20px;
        color: blue;
    }



    .sub-heading-latest {
        margin-left: 16px;
        font-size: 22px;
    }


    .sub-heading-allpost {
        margin-left: 16px;
        font-size: 22px;
    }

    .view-all-link span {
        font-size: 17px;
        margin-top: -20px;
    }


    .card .container hr {
        margin-left: 3px;
        width: 96%;
    }


    .seller-date {
        margin-top: 50px;
    }

    .seller-date p {
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}


/* Medium Screen */
@media only screen and (min-width: 992px) {

    .latest,
    .all-post {
        margin-left: 15px;
    }

    .view-all {
        right: 30px;
    }

    .sub-heading-latest {
        margin-left: 15px;
    }

    .sub-heading-allpost {
        margin-left: 15px;
    }

    .card .height-90 {
        height: 156px;
    }

    .card .item-sold {
        color: red;
        margin-left: 93px;
        font-size: 18px;
    }
}


/* Large Screen */
@media only screen and (min-width: 1900px) {

    .latest,
    .all-post {
        margin-left: 180px;
    }

    .sub-heading-latest {
        margin-left: 180px;
        font-size: 28px;
    }


    .sub-heading-allpost {
        margin-left: 180px;
        font-size: 28px;
    }

    .view-all {
        right: 180px;
    }

    .card .height-90 {
        height: 156px;
    }

    .card .item-sold {
        color: red;
        margin-left: 100px;
        font-size: 18px;
    }
}