.seller-all-post {
    display: flex;
    flex-wrap: wrap;
}


.seller-all-post {
    margin-bottom: 60px;
}


.seller-all-post .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: auto;
    width: 250px;
    margin-right: 30px;
    margin-top: 15px;
}



/* On mouse-over, add a deeper shadow */
.seller-all-post .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.seller-all-post .container {
    padding: 2px 16px;
}


/* Add rounded corners to the top left and the top right corner of the image */
.seller-all-post .latest-img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 180px;
    z-index: 1;
}

.seller-all-post .height-90 {
    height: 154px;
}

.seller-all-post .item-sold {
    color: red;
    margin-left: 77px;
    margin-top: 52px;
    font-size: 18px;
    padding: 25px;
}



.seller-all-post .seller-date {
    margin-top: 30px;
}

.seller-all-post .seller-date {
    padding: 0px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
}

.seller-all-post .container p {
    padding: 0px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.seller-all-post .container .post-action {
    display: flex;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.seller-all-post .container .post-action .btn-sold {
    color: white;
    background-color: #3F00FF;
    padding-top: 8px;
    width: 120px;
    height: 35px;
    margin-right: 40px;
    border-radius: 8px;
    cursor: pointer;
}


.seller-all-post .container .post-action .btn-delete {
    color: white;
    background-color: #FF3131;
    padding-top: 8px;
    width: 60px;
    height: 35px;
    border-radius: 8px;
    cursor: pointer;
}


.seller-all-post .container .post-action .btn-sold:hover {
    font-size: 15px;
}

.seller-all-post .container .post-action .btn-delete:hover {
    font-size: 15px;
}


/* Small Device */
@media only screen and (max-width: 600px) {
    .seller-all-post {
        display: flex;
        flex-wrap: wrap;
    }


    .seller-all-post {
        margin-bottom: 10px;
    }

    .seller-all-post .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
        height: auto;
        width: 165px;
        margin-right: 18px;
        margin-top: 5px;
    }


    /* On mouse-over, add a deeper shadow */
    .seller-all-post .card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    /* Add some padding inside the card container */
    .seller-all-post .container {
        padding: 2px 8px;
    }

    .seller-all-post .container h2 {
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .seller-all-post .container h4 {
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .seller-all-post .container p {
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    /* Add rounded corners to the top left and the top right corner of the image */
    .seller-all-post .latest-img {
        border-radius: 5px 5px 0 0;
        width: 100%;
        height: 110px;
    }

    .seller-all-post .height-90 {
        height: 90px;
    }


    .seller-all-post .item-sold {
        color: red;
        margin-left: 38px;
        margin-top: 18px;
        font-size: 16px;
        padding: 25px;
    }

    .seller-all-post .seller-date {
        margin-top: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .seller-all-post .seller-date .container p {
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .seller-all-post .container .post-action {
        display: flex;
        border-radius: 8px;
        padding: 5px;
    }

    .seller-all-post .container .post-action .btn-sold {
        color: white;
        background-color: #3F00FF;
        padding-top: 6px;
        width: 70px;
        height: 45px;
        margin-right: 10px;
        border-radius: 8px;
        cursor: pointer;
    }


    .seller-all-post .container .post-action .btn-delete {
        color: white;
        background-color: #FF3131;
        padding-top: 6px;
        width: 60px;
        height: 35px;
        border-radius: 8px;
        cursor: pointer;
    }

}