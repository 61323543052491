.post-details {
    display: flex;
    height: 120vh;
    background-color: rgb(223, 223, 223);
}

.img-carousel {
    position: relative;
    height: 450px;
    width: 700px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 95px;
    margin-top: 80px;
    background-color: rgb(143, 142, 142);
    border-radius: 8px;
    border: rgb(255, 255, 255) 2px;
}

.wrapper {
    display: contents;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
}

.wrapper img {
    width: 800px;
    height: 450px;
    border-radius: 8px;
    object-fit: contain;
}

.buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

button {
    background: transparent;
    color: #fde400;
    border: none;
    cursor: pointer;
    padding: 10px;
}

button i {
    font-size: 2rem;
}

button:hover {
    color: grey;
}

.image-number {
    font-size: 30px;
    color: black;
    position: absolute;
    margin-top: 540px;
    margin-left: 728px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
}

.vehicle-details {
    width: 550px;
    height: 140px;
    margin-top: 80px;
    margin-left: 30px;
    border: grey 2px;
    border-radius: 8px;
    padding: 30px;
    background-color: white;
}

.vehicle-price {
    font-size: 40px;
    font-weight: 600;
}

.post-date {
    right: 125px;
    position: absolute;
    margin-top: -25px;
}

.post-status {
    position: absolute;
    right: 125px;
    margin-top: -45px;
    font-size: 30px;
}

.post-status.sold {
    color: red;
}

.post-status.active {
    color: rgb(6, 177, 6);
}


.post-description {
    position: absolute;
    background-color: white;
    right: 85px;
    width: 610px;
    height: auto;
    margin-top: 300px;
    border-radius: 8px;
}

.post-description h2 {
    margin-top: 25px;
    margin-left: 25px;
}

textarea {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 17px;
    border: none;
    overflow: hidden;
    text-decoration: none;
}

textarea::-webkit-resizer {
    display: none;
}

textarea:empty::after {
    content: '';
    display: block;
    height: 0;
    overflow: hidden;
}

textarea:not(:empty) {
    height: auto;
    min-height: 283px;
}

.whatsapp-chat {
    text-decoration: none;
    color: white;
    font-size: 20px;
}

.whatsapp-chat-button {
    cursor: pointer;
    background-color: #25D366;
    width: 200px;
    height: 40px;
    border-radius: 15px;
    margin-left: 390px;
    margin-top: 80px;
    margin-bottom: 25px;
}


#whatsapp-icon {
    margin-right: 8px;
    font-size: 1.3rem;
    color: white;
}

.whatsapp-chat-button:hover {
    background-color: #06aa42;
}

.vehicle-details hr {
    width: 100%;
    margin-left: 2px;
    border: solid 1px;
}

/* Large Device */
@media only screen and (min-width: 1900px) {
    .post-details {
        display: flex;
        height: 100vh;
        background-color: rgb(223, 223, 223);
    }


    .img-carousel {
        position: relative;
        height: 450px;
        width: 700px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 280px;
        margin-top: 120px;
        background-color: rgb(143, 142, 142);
        border-radius: 8px;
        border: white 2px;
    }

    .image-number {
        font-size: 30px;
        color: black;
        position: absolute;
        margin-top: 580px;
        margin-left: 912px;
        background-color: white;
        padding: 10px;
        border-radius: 8px;
    }

    .vehicle-details {
        width: 550px;
        height: 140px;
        margin-top: 120px;
        margin-left: 30px;
        border: grey 2px;
        border-radius: 8px;
        padding: 30px;
        background-color: white;
    }

    .post-description {
        position: absolute;
        background-color: white;
        right: 282px;
        width: 610px;
        height: auto;
        margin-top: 339px;
        border-radius: 8px;
    }


    .post-status {
        position: absolute;
        right: 330px;
        margin-top: -40px;
        font-size: 30px;
        color: red;
    }

    .post-date {
        right: 330px;
        position: absolute;
        margin-top: -25px;
    }

    .vehicle-details hr {
        width: 95.5%;
        margin-left: 2px;
        border: solid 1px;
    }

    .whatsapp-chat-button {
        cursor: pointer;
        background-color: #25D366;
        width: 200px;
        height: auto;
        border-radius: 15px;
        margin-left: 390px;
        margin-top: 65px;
        margin-bottom: 25px;
    }


    #whatsapp-icon {
        margin-right: 8px;
        font-size: 1.3rem;
        color: white;
    }

    .whatsapp-chat-button:hover {
        background-color: #06aa42;
    }
}

/* Small Device */
@media only screen and (max-width: 600px) {

    .post-details {
        display: block;
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: rgb(223, 223, 223);
    }

    .img-carousel {
        position: relative;
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgb(223, 223, 223);
        border: white 2px;
        border-radius: 0px;
        object-fit: contain;
    }

    .wrapper img {
        width: 400px;
        height: 250px;
        object-fit: contain;
        border-radius: 0px;
    }


    .image-number {
        display: hidden;
        right: 200px;
    }

    .vehicle-details {
        width: 100%;
        height: auto;
        margin-top: 0px;
        margin-left: 0px;
        border: grey 2px;
        border-radius: 0px;
        padding: 15px;
        background-color: white;
    }

    .vehicle-price {
        font-size: 20px;
        font-weight: 700;
    }

    .vehicle-details h3 {
        font-size: 16px;
        font-weight: 500;
    }

    .post-description {
        position: absolute;
        background-color: white;
        right: 0px;
        width: 100%;
        height: auto;
        margin-top: 10px;
        border-radius: 0px;
    }


    .post-status {
        position: absolute;
        right: 15px;
        margin-top: -5px;
        font-size: 30px;
        color: red;
    }

    .post-date {
        right: 15px;
        position: absolute;
        margin-top: -23px;
    }

    .post-description h2 {
        font-size: 18px;
        margin-top: 20px;
        margin-left: 15px;
    }

    .description {
        margin-left: 15px;
        margin-top: 15px;
        font-size: 16px;
        padding-bottom: 250px;
    }

    .whatsapp-chat {
        text-decoration: none;
        color: white;
        font-size: 20px;
    }

    .whatsapp-chat-button {
        cursor: pointer;
        background-color: #25D366;
        width: 95px;
        height: 40px;
        position: absolute;
        border-radius: 15px;
        margin-left: 280px;
        top: -60px;
        bottom: 0px;
    }

    .whatsapp-chat {
        text-decoration: none;
        color: white;
        font-size: 18px;
    }

    #whatsapp-icon {
        margin-right: 8px;
        font-size: 1.1rem;
        color: white;
    }

    .vehicle-details hr {
        width: 91%;
        margin-left: 2px;
        border: solid 1px;
    }
}