/* .login{
    
} */

.box-login {
    width: 380px;
    height: 430px;
    border-radius: 25px;
    margin-top: 130px;
    background-color: #f0d005;
    margin-left: 580px;
}

.heading-login {
    margin-left: 148px;
    padding-top: 55px;
    color: white;
}

.secure-icon {
    text-align: center;
    margin-top: 20px;
    font-size: 35px;
    color: white;
}


.otp-label {
    margin-left: 120px;
    font-size: 20px;
    font-weight: 600;
    color: white;
}


.number-label {
    margin-left: 104px;
    font-size: 20px;
    font-weight: 600;
    color: white;
}


.otp-container {
    margin-left: 45px;
    margin-top: 30px;
}

.otp-container input {
    border: none;
    font-weight: 600;
    font-size: 18px;
}

.btn-container {
    margin-left: 94px;
}

.otp-verify-btn {
    margin-top: 25px;
    width: 190px;
    height: 35px;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: black;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
}


.otp-send-btn {
    margin-top: 25px;
    width: 190px;
    height: 35px;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: black;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
}



.loading-spinner {
    margin-right: 10px;
    margin-bottom: -4px;
}


.phone-container {
    margin-left: 95px;
    margin-top: 25px;
    color: white;
}

.phone-container input {
    width: 180px;
    height: 35px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 17px;
}



.error-phone-input{
    color: red;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 113px;
}

@media only screen and (max-width: 600px) {

    .box-login {
        margin-left: 0px;
        margin-top: 0px;
        background-color: #f0d005;
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }

    .heading-login {
        margin-left: 155px;
        padding-top: 150px;
        color: white;
        position: absolute;
    }

    .secure-icon {
        text-align: center;
        margin-top: 0px;
        padding-top: 250px;
        font-size: 40px;
        color: white;
    }


    .otp-label {
        margin-left: 125px;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .number-label {
        margin-left: 108px;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .otp-container {
        margin-left: 51px;
        margin-top: 30px;
    }

    .btn-container {
        margin-left: 100px;
    }


    .phone-container {
        margin-left: 100px;
        margin-top: 25px;
        color: white;
    }
    
}

@media only screen and (min-width: 1900px) {
    .box-login {
        width: 380px;
        height: 430px;
        border-radius: 25px;
        margin-top: 220px;
        background-color: #f0d005;
        margin-left: 765px;
    }
}