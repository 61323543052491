.footer-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0d005;
    width: 100%;
    height: 165px;
    justify-content: center;
}

.details-row {
    display: flex;
    flex-wrap: wrap;
}

.details-row .details-coloumn p,
.details-row .details-coloumn a{
    margin: 5px;
}

.details-row .details-coloumn p {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 19px;
    font-weight: 600;
}

.details-row .details-coloumn a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: blue;
    font-size: 19px;
    font-weight: 600;
}

.details-coloumn:first-child {
    border-right: 1px solid #000;
    padding-right: 20px;
    margin-right: 20px;
}

.copyright{
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    .footer-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f0d005;
        width: 100%;
        height: 165px;
        justify-content: center;
    }
    
    .details-row {
        display: flex;
        flex-wrap: wrap;
    }
    
    .details-row .details-coloumn p,
    .details-row .details-coloumn a{
        margin: 5px;
    }
    
    .details-row .details-coloumn p {
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 15px;
        font-weight: 600;
    }
    
    .details-row .details-coloumn a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: blue;
        font-size: 15px;
        font-weight: 600;
    }
    
    .details-coloumn:first-child {
        border-right: 1px solid #000;
        padding-right: 20px;
        margin-right: 20px;
    }
    
    .copyright{
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 13px;
    }
}