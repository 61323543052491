.all-Post {
    padding-top: 10px;
    padding-left: 70px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.all-post-heading{
    margin-left: 70px;
    font-size: 30px;
    font-weight: 900;
    margin-top: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.all-Post .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 350px;
    width: 250px;
    margin-right: 30px;
    margin-top: 25px;
}

/* On mouse-over, add a deeper shadow */
.all-Post .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.all-Post .container {
    padding: 2px 16px;
}

/* Add rounded corners to the top left and the top right corner of the image */
.all-Post .latest-img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 180px;
}

.all-Post .height-90 {
    height: 156px;
}

.all-Post .item-sold {
    color: red;
    margin-left: 84px;
    font-size: 18px; 
}

.all-Post .seller-date{
    margin-top: 30px;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



/* Small Screen */
@media only screen and (max-width: 600px) {

    .all-post-heading{
        margin-left: 25px;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
    }

    .all-Post {
        padding-left: 25px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .all-Post .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
        height: auto;
        width: 165px;
        margin-right: 15px;
        margin-top: 5px;
        padding-bottom: 5px;
    }


    .all-Post .latest-img {
        border-radius: 5px 5px 0 0;
        width: 100%;
        height: 110px;
    }

    .all-Post .height-90 {
        height: 88px;
    }

    .all-Post .item-sold {
        color: red;
        margin-left: 49px;
        font-size: 14px;
    }

    .all-Post .container {
        padding: 2px 5px;
    }

    .all-Post .container h2{
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .all-Post .container h4{
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    
    .all-Post .container p{
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .all-Post hr{
        margin-left: 3px;
        width: 96%;
    }


    .all-Post .seller-date {
        margin-top: 20px;
    }


    .all-Post .seller-date p{
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}


/* Large Screen */
@media only screen and (min-width: 1900px){
    .all-Post {
        padding-left: 120px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .all-post-heading {
        margin-left: 120px;
        font-size: 30px;
        font-weight: 600;
        margin-top: 20px;
    }
}