.banner-img{
    width: 100%;
    object-fit: contain; 
}

.banner-mobile{
    display: none;
}


@media only screen and (max-width: 600px){
    .banner-img{
        display: none;
        object-fit: contain;
    }

    .banner-mobile{
        display: flex;
        width: 100%;
    }
}