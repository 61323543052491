.latest-post {
    padding-top: 10px;
    padding-left: 70px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.latest-heading {
    margin-left: 70px;
    font-size: 30px;
    font-weight: 900;
    margin-top: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.latest-post .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 350px;
    width: 250px;
    margin-right: 30px;
    margin-top: 25px;
    cursor: pointer;
}

/* On mouse-over, add a deeper shadow */
.latest-post .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.latest-post .container {
    padding: 2px 16px;
}

/* Add rounded corners to the top left and the top right corner of the image */
.latest-post .latest-img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 180px;
    object-fit: contain;
    padding-top: 15px;
}

.latest-post .height-90 {
    height: 156px;
}

.latest-post .item-sold {
    color: red;
    margin-left: 84px;
    font-size: 18px;
}



.latest-post .seller-date {
    margin-top: 15px;
}

.latest-post hr {
    margin-left: 0px;
    width: 100%;
}



/* Small Screen */
@media only screen and (max-width: 600px) {

    .latest-heading {
        margin-left: 25px;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
    }

    .latest-post {
        padding-left: 25px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .latest-post .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
        height: 280px;
        width: 165px;
        margin-right: 15px;
        margin-top: 5px;
        cursor: pointer;
    }


    .latest-post .latest-img {
        border-radius: 5px 5px 0 0;
        width: 100%;
        height: 110px;
        object-fit: contain;
        padding-top: 15px;
    }

    .latest-post .height-90 {
        height: 88px;
    }

    .latest-post .item-sold {
        color: red;
        margin-left: 49px;
        font-size: 14px;
    }

    .latest-post .container {
        padding: 2px 5px;
    }

    .latest-post .container h2 {
        font-size: 16px;
    }

    .latest-post .container h4 {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .latest-post .container p {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .latest-post hr {
        margin-left: 0px;
        width: 100%;
    }


    .latest-post .seller-date {
        margin-top: 25px;
    }


    .latest-post .seller-date p {
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}


/* Large Screen */
@media only screen and (min-width: 1900px) {
    .latest-post {
        padding-left: 120px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .latest-heading {
        margin-left: 120px;
        font-size: 30px;
        font-weight: 600;
        margin-top: 20px;
    }

    .latest-post .item-sold {
        color: red;
        margin-left: 84px;
        font-size: 18px;
    }
}