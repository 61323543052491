.admin-all-sellers {
    width: 850px;
    height: auto;
    margin-top: 50px;
    margin-left: 30px;
    padding-bottom: 50px;
    padding-right: 30px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.admin-all-sellers h2 {
    font-size: 22px;
    padding: 30px 0px 0px 50px;
}

#sellers {
    margin-left: 50px;
    margin-top: 30px;
}

#sellers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 90%;
}

#sellers td,
#sellers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#sellers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#sellers tr:hover {
    background-color: #ddd;
}

#sellers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}