.AdminHeader{
    background-color: #f0d005;
    width: 100%;
    height: 60px;
    display: flex;
}

textarea:focus, input:focus{
    outline: none;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
}

nav ul{
    display: flex;
    position: absolute;
    margin-top: 6px;
    right: 50px;
    font-size: 18px;
    font-weight: 600;
    list-style: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.logo{
    width: 250px;
    margin-left: 5px;
    margin-top: 1px;
}


nav ul i{
    font-size: 20px;
    color: rgb(0, 0, 0);
}


nav .account{
    margin-right: 15px;
}

nav span{
    margin-left: 15px;
}



nav .fa-bars,.fa-xmark{
    display: none;
}



/* CSS for Small Device */
@media only screen and (max-width: 600px) {

    .logo{
        width: 180px;
        margin-left: -5px;
        margin-top: 10px;
    }
    

    nav input{
        display: none;
    }


    nav .fa-bars,.fa-xmark{
        display: block;
        font-size: 25px;
    }

    nav ul{
        display: block;
        background-color: #f0d005;
        position: fixed;
        top: 0;
        right: -200px;
        width: 130px;
        height: 100vh;
        padding-top: 50px;
        z-index: 2;
    }

    nav ul li{
        display: block;
        margin: 25px;
        margin-left: 10px;
    }

    nav .fa-user{
        display: none;
    }

    nav .fa-xmark{
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
    }

    nav .fa-bars{
        position: absolute;
        right: 20px;
        margin-top: 6px;
    }

    nav .fa-sharp{
        position: absolute;
        top: -48px;
        right: 40px;
        font-size: 20px;
        margin-top: 5px;
        cursor: pointer;
    }

    
}


/* Medium Screen */
@media only screen and (min-width: 992px) {
 
}
