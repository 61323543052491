.AdminLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
  }

.login-container {
    width: 350px;
    height: 350px;
    background-color: #f0d005;
    border-radius: 25px;
    padding: 15px;
}

.login-container .admin-login-heading{
    text-align: center;
    margin-top: 40px;
    font-size: 20px;
    color: white;
}

.login-container .admin-login-inputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.login-container .admin-login-inputs input{
    width: 70%;
    height: 40px;
    border: none;
    border-radius: 25px;
    margin-top: 20px;
    font-size: 18px;
    padding-left: 25px;
}

.login-container .admin-login-button{
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}

.login-container .admin-login-button button{
    background-color: white;
    margin-left: 40px;
    width: 77%;
    border-radius: 25px;
    height: 40px;
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.login-container .admin-login-button button:hover{
    background-color: rgb(4, 196, 4);
    color: white;
    border: 2px solid;
}




