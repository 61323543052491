/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.admin-page {
    display: flex;
    flex-direction: row;
}

.admin-side-menu {
    width: 280px;
    height: 865px;
    margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


/* User Details */
.user-details .user-sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.user-details .user-sub-heading h2 {
    color: #878787;
    font-size: 22px;
    font-family: Roboto, Arial, sans-serif;
}


.user-details .user-sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}

.user-details .admin-div-button {
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 12px 5px 12px 66px;
    cursor: pointer;
}

.user-details .admin-div-button:hover {
    font-weight: 600;
    color: #f0d005;
    background-color: #f5faff;
}

.admin-side-menu hr {
    width: 90%;
    color: #dfdfdf;
    margin-left: 15px;
    margin-top: 10px;
}


/* Seller Details */
.seller-details .seller-sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.seller-details .seller-sub-heading h2 {
    color: #878787;
    font-size: 22px;
    font-family: Roboto, Arial, sans-serif;
}


.seller-details .seller-sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}

.seller-details .admin-div-button {
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 12px 5px 12px 66px;
    cursor: pointer;
}

.seller-details .admin-div-button:hover {
    font-weight: 600;
    color: #f0d005;
    background-color: #f5faff;
}


/* Post Details */
.seller-post-details .seller-post-sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.seller-post-details .seller-post-sub-heading h2 {
    color: #878787;
    font-size: 22px;
    font-family: Roboto, Arial, sans-serif;
}


.seller-post-details .seller-post-sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}

.seller-post-details .admin-div-button {
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 12px 5px 12px 66px;
    cursor: pointer;
}

.seller-post-details .admin-div-button:hover {
    font-weight: 600;
    color: #f0d005;
    background-color: #f5faff;
}



/* Banner Details */
.banner-details .banner-sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.banner-details .banner-sub-heading h2 {
    color: #878787;
    font-size: 22px;
    font-family: Roboto, Arial, sans-serif;
}


.banner-details .banner-sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}

.banner-details .admin-div-button {
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 12px 5px 12px 66px;
    cursor: pointer;
}

.banner-details .admin-div-button:hover {
    font-weight: 600;
    color: #f0d005;
    background-color: #f5faff;
}


/* Logout Button */
.admin-logout .admin-logout-sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.admin-logout .admin-logout-sub-heading h2 {
    color: #878787;
    font-size: 22px;
    font-family: Roboto, Arial, sans-serif;
    cursor: pointer;
}

.admin-logout .admin-logout-sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}