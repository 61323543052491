.search-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    margin-top: 25px;
}

.search-box input {
    width: 20%;
    height: 45px;
    border-radius: 25px;
    padding-left: 20px;
    font-size: 18px;
    position: relative;
}

.search-box i {
    position: relative;
    margin-top: 14px;
    font-size: 1.3rem;
    margin-left: -40px;
}

.center {
    text-align: center;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .search-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        margin-top: 20px;
    }

    .search-box input {
        width: 75%;
        height: 35px;
        border-radius: 25px;
        padding-left: 20px;
        font-size: 17px;
        position: relative;
        margin-left: -18px;
        background-color: rgb(236, 232, 232);
    }

    .search-box i {
        position: relative;
        margin-top: 8px;
        font-size: 1.3rem;
        margin-left: -35px;
    }

    .center {
        text-align: center;
        margin-top: 60px;
        font-size: 20px;
        font-weight: 700;
    }
}