.header {
    background-color: #f0d005;
    width: 100%;
    height: 70px;
    display: flex;
}

textarea:focus,
input:focus {
    outline: none;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
}

nav ul {
    display: flex;
    position: absolute;
    margin-top: 6px;
    right: 50px;
    font-size: 18px;
    font-weight: 600;
    list-style: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav .search-bar {
    display: flex;
    flex-direction: row;
    margin-left: 630px;
    margin-top: 5px;
    width: 130px;
    height: 38px;
    background-color: white;
    border-radius: 25px;
    border: 2px solid;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
}

nav .search-label {
    font-size: 19px;
    font-weight: 600;
    margin-top: 3px;
    margin-right: 5px;
}

nav .search-icon {
    margin-top: 8px;
}

.logo {
    width: 250px;
    margin-left: 5px;
    margin-top: 6px;
}


nav ul i {
    font-size: 20px;
    color: rgb(0, 0, 0);
}

nav .login {
    margin-right: 50px;
    margin-top: 10px;
}

nav .account {
    margin-right: 15px;
}

nav span {
    margin-left: 15px;
}

nav ul .login {
    cursor: pointer;
}


nav .fa-bars,
.fa-xmark {
    display: none;
}

nav ul .user-name-display {
    text-decoration: none;
    color: black;
}

nav ul .user-name-display:hover {
    text-decoration: none;
    color: white;
}

nav .search-icon-small {
    display: none;
}


/* CSS for Small Device */
@media only screen and (max-width: 600px) {
    .header {
        overflow-y: hidden;
        height: 65px;
    }

    .logo {
        width: 180px;
        margin-left: -5px;
        margin-top: 15px;
    }

    nav .search-bar {
        display: none;
    }

    nav .search-label {
        display: none;
    }

    nav .search-icon {
        display: none;
    }

    nav .search-icon-small {
        display: block;
        position: absolute;
        top: 19px;
        right: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
    }


    nav .fa-bars,
    .fa-xmark {
        display: block;
        font-size: 25px;
    }

    nav ul {
        display: block;
        background-color: #f0d005;
        position: fixed;
        top: 0px;
        right: -250px;
        width: 130px;
        height: 100vh;
        padding-top: 50px;
        z-index: 2;
    }

    nav ul li {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-left: 10px;
    }

    nav ul li span {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-left: 10px;
    }

    nav .fa-user {
        display: none;
    }

    nav .fa-xmark {
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
    }

    nav .fa-bars {
        position: absolute;
        top: 15px;
        right: 20px;
        margin-top: -45px;
    }


}