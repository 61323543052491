/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.profile-page {
    display: flex;
}

.profile-body {
    display: block;
}



.profile-details {
    width: 320px;
    height: 75px;
    margin-left: 150px;
    margin-top: 50px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.personal-information {
    display: flex;
}

.personal-items {
    margin-top: 12px;
}

.personal-items span {
    font-size: 18px;
    font-weight: 600;
    font-family: Roboto, Arial, sans-serif;
}

.profile-box {
    display: flex;
    padding: 12px 25px 12px 25px;
}

.profile-avatar {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}

.side-menu {
    width: 320px;
    height: 450px;
    margin-left: 150px;
    margin-top: 25px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.account-information {
    padding: 0px;
}

.sub-heading {
    display: flex;
    padding: 25px 25px 0px 35px;
}

.sub-heading h2 {
    color: #878787;
    font-size: 20px;
    font-family: Roboto, Arial, sans-serif;
    cursor: pointer;
}

.sub-heading i {
    color: #878787;
    font-size: 1.2rem;
    margin-right: 10px;
}

.div-button {
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 12px 5px 12px 66px;
    cursor: pointer;
}

.div-button:hover {
    font-weight: 600;
    color: #f0d005;
    background-color: #f5faff;
}

hr {
    width: 90%;
    color: #dfdfdf;
    margin-left: 15px;
    margin-top: 10px;
}



/* .personal-information-details */
.personal-information-details {
    width: 800px;
    height: 450px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-left: 25px;
}

.personal-information-details p {
    padding: 30px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
}

.sucess-message {
    padding: 30px 0px 0px 290px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(22, 173, 22);
}

.full-name {
    display: flex;
    padding: 20px 0px 0px 50px;
}

.full-name input {
    margin-right: 25px;
    width: 250px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile {
    display: inline-block;
    padding: 45px 0px 0px 50px;
}

.mobile label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile input {
    width: 220px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.email {
    display: block;
    padding: 45px 0px 0px 50px;
}

.email label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.email input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.update-btn {
    display: flex;
    padding: 45px 0px 0px 50px;
    padding-right: 50px;
    justify-content: flex-end;
}

.update-btn button {
    width: 100px;
    height: 40px;
    background-color: #f0d005;
    font-size: 25px;
    padding: 10px;
    border-radius: 20px;
    padding-top: 5px;
    font-weight: 600;
    color: white;
}


.update-btn button:hover {
    color: black;
    border: 2px solid;
}




/* .seller-account-details */
.seller-account-details {
    width: 800px;
    height: 495px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-left: 25px;
}

.seller-account-details p {
    padding: 30px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
}

.seller-account-details .sucess-message {
    padding: 30px 0px 0px 290px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(22, 173, 22);
}

.seller-name {
    padding: 35px 0px 0px 50px;
}

.seller-name label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.seller-name input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.seller-place {
    padding: 35px 0px 0px 50px;
}

.seller-place label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.seller-place input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.whatsapp-number {
    padding: 35px 0px 0px 50px;
}

.whatsapp-number label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.whatsapp-number input {
    width: 220px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.insta-url {
    padding: 35px 0px 0px 50px;
}

.insta-url label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.insta-url input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}




/* .subscription-details */
.subscription-details {
    width: 800px;
    height: 355px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-left: 25px;
}

.subscription-details p {
    padding: 30px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
}

.current-subscription {
    padding: 35px 0px 0px 50px;
}

.current-subscription label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.current-subscription p {
    background-color: #f0d005;
    padding: 15px;
    margin-top: 20px;
    width: max-content;
}

.subscription-amount {
    padding: 35px 0px 0px 50px;
}

.subscription-amount label {
    font-size: 17px;
    font-weight: 500;
    margin-right: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.subscription-payment {
    padding: 35px 0px 0px 50px;
}


.subscription-payment label {
    font-size: 17px;
    font-weight: 500;
    margin-right: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.subscription-payment button {
    width: 180px;
    height: 40px;
    background-color: #f0d005;
    font-size: 25px;
    padding: 10px;
    border-radius: 20px;
    padding-top: 5px;
    font-weight: 600;
    color: white;
}

.subscription-payment button:hover {
    color: #000;
    border: 2px solid;
}



/* .all-post-details  */
.all-post-details {
    width: 980px;
    height: 620px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-left: 25px;
    margin-bottom: 50px;
    padding-bottom: 35px;
}

.all-post-details p {
    padding: 30px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
}


.seller-post {
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
    padding: 50px;
    padding-right: 0px;
    margin-top: 30px;
    width: 860px;
    height: 400px;
    overflow: auto;
}

.seller-post::-webkit-scrollbar {
    width: 8px;
}

.seller-post::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.seller-post::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.seller-post::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* .new-post-details */
.new-post-details {
    width: 800px;
    height: auto;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-left: 25px;
    margin-bottom: 50px;
    padding-bottom: 35px;
}

.new-post-details p {
    padding: 30px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
}

.post-name {
    padding: 35px 0px 0px 50px;
}

.post-name label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.post-name input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.price {
    padding: 35px 0px 0px 50px;
}

.price label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.price input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.model {
    padding: 35px 0px 0px 50px;
}

.model label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.model input {
    width: 300px;
    height: 45px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description {
    padding: 15px 0px 0px 25px;
    display: flex;
}

.description label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description textarea {
    width: 450px;
    height: 130px;
    resize: none;
    border: none;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    padding-top: 15px;
    margin-left: 25px;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.image-label {
    padding: 35px 0px 0px 50px;
}

.image-label label {
    font-size: 17px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.image {
    padding: 15px 0px 0px 50px;
}

.image label {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted black;
    border-radius: 20px;
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.image i {
    font-size: 1.5rem;
    color: #696969;
}

.image input {
    display: none;
}

.display-image {
    padding: 15px 0px 0px 35px;
    display: flex;
    flex-wrap: wrap;
}

.image-box {
    display: flex;
    flex-direction: row;
}

.image-box img {
    width: 90px;
    height: 100px;
    margin-left: 15px;
    margin-bottom: 15px;
    object-fit: contain;
}

.image-box i {
    font-size: 1.3rem;
    color: red;
    margin-bottom: 70px;
    margin-left: -5px;
}

.progressbar {
    width: 80%;
    margin-left: 50px;
}

.submit-btn {
    padding: 15px 0px 0px 50px;
}

.submit-btn {
    display: flex;
    padding: 45px 0px 0px 50px;
    padding-right: 50px;
    justify-content: flex-end;
}

.submit-btn button {
    width: 120px;
    height: 40px;
    background-color: #f0d005;
    font-size: 25px;
    padding: 10px;
    border-radius: 20px;
    padding-top: 5px;
    font-weight: 600;
    color: white;
}


.submit-btn button:hover {
    color: black;
    border: 2px solid;
}



/* Small Device */
@media only screen and (max-width: 600px) {
    .profile-page {
        display: block;
        overflow-x: hidden;
    }

    .profile-body {
        display: block;
    }



    .profile-details {
        width: 100%;
        height: 75px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .personal-information {
        display: flex;
    }

    .personal-items {
        margin-top: 12px;
    }

    .personal-items span {
        font-size: 18px;
        font-weight: 600;
        font-family: Roboto, Arial, sans-serif;
    }

    .profile-box {
        display: flex;
        padding: 12px 25px 12px 25px;
    }

    .profile-avatar {
        width: 50px;
        height: 50px;
        margin-right: 5px;
    }

    .side-menu {
        width: 100%;
        height: 350px;
        margin-left: 0px;
        margin-top: 25px;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }


    .account-information {
        padding: 0px;
    }

    .sub-heading {
        display: flex;
        padding: 10px 25px 0px 25px;
    }

    .sub-heading h2 {
        color: #878787;
        font-size: 18px;
        font-family: Roboto, Arial, sans-serif;
    }

    .sub-heading i {
        color: #878787;
        font-size: 1.1rem;
        margin-right: 10px;
    }

    .div-button {
        font-size: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 6px 5px 12px 50px;
        cursor: pointer;
    }

    .div-button:hover {
        font-weight: 600;
        color: #f0d005;
        background-color: #f5faff;
    }

    hr {
        width: 90%;
        color: #dfdfdf;
        margin-left: 15px;
        margin-top: 10px;
    }


    /* .personal-information */
    .personal-information-details {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-top: 25px;
        margin-left: 0px;
        margin-bottom: 50px;
    }

    .personal-information-details p {
        padding: 20px 0px 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }

    .personal-information-details span {
        padding: 30px 0px 0px 110px;
        font-size: 20px;
        font-weight: 700;
        color: rgb(22, 173, 22);
    }

    .full-name {
        display: block;
        padding: 0px 0px 0px 25px;
    }

    .full-name input {
        margin-right: 25px;
        margin-top: 20px;
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .mobile {
        display: flex;
        flex-direction: column;
        padding: 40px 0px 0px 25px;
    }

    .mobile label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .mobile input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .email {
        display: flex;
        flex-direction: column;
        padding: 40px 0px 0px 25px;
    }

    .email label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


    .email input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .update-btn {
        display: flex;
        padding: 40px 0px 0px 25px;
        padding-right: 0px;
        padding-bottom: 20px;
        justify-content: flex-start;
    }

    .update-btn button {
        width: 80px;
        height: 35px;
        background-color: #f0d005;
        font-size: 20px;
        padding: 10px;
        border-radius: 20px;
        padding-top: 5px;
        font-weight: 600;
    }


    .update-btn button:hover {
        color: black;
    }


    /* .seller-account-details */
    .seller-account-details {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-top: 25px;
        margin-left: 0px;
        margin-bottom: 50px;
    }

    .seller-account-details p {
        padding: 25px 0px 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }

    .seller-account-details .sucess-message {
        padding: 30px 0px 0px 110px;
        font-size: 20px;
        font-weight: 700;
        color: rgb(22, 173, 22);
    }


    .seller-name {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .seller-name label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .seller-name input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .seller-place {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .seller-place label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .seller-place input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


    .whatsapp-number {
        padding: 40px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .whatsapp-number label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .whatsapp-number input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }



    .insta-url {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .insta-url label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .insta-url input {
        width: 200px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }



    /* .subscription-details */
    .subscription-details {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-top: 25px;
        margin-left: 0px;
        margin-bottom: 50px;
    }

    .subscription-details p {
        padding: 30px 0px 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }

    .current-subscription {
        padding: 35px 0px 0px 25px;
    }

    .current-subscription label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .current-subscription p {
        background-color: #f0d005;
        padding: 15px;
        margin-top: 20px;
        width: max-content;
    }

    .subscription-amount {
        padding: 35px 0px 0px 25px;
    }

    .subscription-amount label {
        font-size: 16px;
        font-weight: 500;
        margin-right: 25px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .subscription-payment {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }


    .subscription-payment label {
        font-size: 16px;
        font-weight: 500;
        margin-right: 25px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


    .subscription-payment button {
        width: 120px;
        height: 30px;
        background-color: #f0d005;
        font-size: 18px;
        padding: 10px;
        border-radius: 20px;
        padding-top: 5px;
        margin-top: 15px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .subscription-payment button:hover {
        color: #000;
    }




    /* .all-post-details  */
    .all-post-details {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-top: 25px;
        margin-left: 0px;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }

    .all-post-details p {
        padding: 30px 0px 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }

    .seller-post {
        display: flex;
        padding: 10px 0px 0px 20px;
        margin-right: 0px;
        width: 100%;
        height: auto;
    }


    /* .new-post-details */
    .new-post-details {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-top: 25px;
        margin-left: 0px;
        margin-bottom: 50px;
        padding-bottom: 35px;
    }

    .new-post-details p {
        padding: 30px 0px 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }

    .new-post-details .sucess-message {
        padding: 30px 0px 0px 110px;
        font-size: 20px;
        font-weight: 700;
        color: rgb(22, 173, 22);
    }

    .post-name {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .post-name label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .post-name input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .price {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .price label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .price input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


    .model {
        padding: 35px 0px 0px 25px;
        display: flex;
        flex-direction: column;
    }

    .model label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .model input {
        width: 220px;
        height: 45px;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .description {
        padding: 15px 0px 0px 10px;
        display: flex;
        flex-direction: column;
    }

    .description label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .description textarea {
        width: 280px;
        height: 130px;
        resize: none;
        border: 2px solid grey;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding-left: 20px;
        padding-top: 15px;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 17px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .image-label {
        padding: 35px 0px 0px 25px;
    }

    .image-label label {
        font-size: 16px;
        font-weight: 500;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


    .image {
        padding: 15px 0px 0px 25px;
    }

    .image label {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px dotted black;
        border-radius: 20px;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

    .image i {
        font-size: 1.3rem;
        color: #696969;
    }

    .image input {
        display: none;
    }

    .display-image {
        padding: 15px 0px 0px 13px;
        display: flex;
        flex-wrap: wrap;
    }

    .image-box {
        display: flex;
        flex-direction: row;
    }

    .image-box img {
        width: 80px;
        height: 90px;
        margin-left: 13px;
        margin-bottom: 15px;
        object-fit: contain;
    }

    .image-box i {
        font-size: 1.3rem;
        color: red;
        margin-bottom: 70px;
        margin-left: -5px;
    }

    .progressbar {
        width: 80%;
        margin-left: 25px;
    }

    .submit-btn {
        padding: 15px 0px 0px 25px;
    }

    .submit-btn {
        display: flex;
        padding: 15px 0px 0px 25px;
        padding-right: 0px;
        justify-content: flex-start;
    }

    .submit-btn button {
        width: 95px;
        height: 35px;
        background-color: #f0d005;
        font-size: 20px;
        padding: 10px;
        border-radius: 20px;
        padding-top: 6px;
        font-weight: 600;
    }


    .submit-btn button:hover {
        color: black;
    }
}