.large-device {
    width: 970px;
    height: auto;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 60px;
    padding-bottom: 50px;
    padding-right: 30px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.large-device h2{
    font-size: 22px;
    padding: 30px 0px 0px 50px;
}


.large-device h4{
    font-size: 19px;
    padding: 30px 0px 0px 50px;
}

.large-device .upload-success-message{
    font-size: 19px;
    padding: 30px 0px 0px 390px;
    font-weight: 700;
    color: rgb(3, 172, 3);
}

.large-device .upload-error-message{
    font-size: 19px;
    padding: 30px 0px 0px 390px;
    font-weight: 700;
    color: rgb(255, 0, 0);
}

.img-container{
    margin-left: 50px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 1px;
    border-radius: 10px;
    width: max-content;
    align-items: center;
    cursor: pointer;
}

.img-container input {
    display: none;
}

.img-container i{
 font-size: 1.5rem;
}

.large-device button{
    padding: 15px;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 18px;
    background-color: #ffd902;
    border-radius: 10px;
}

.large-device button:hover{
    background-color: rgb(6, 199, 6);
    border: 2px solid black;
    color: white;
}

.large-device .thumbnail{
    display: flex;
    flex-direction: column;
}

.large-device .thumbnail img{
    width: 900px;
    height: 250px;
    margin-left: 50px;
    margin-top: 15px;
    object-fit: contain;
}

.large-device .thumbnail .delete-btn{
    display: flex;
    margin-left: 50px;
    margin-top: 15px;
    justify-items: center;
}

.large-device .thumbnail .delete-btn i{
    margin-left: 15px;
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
}

.large-device .thumbnail .delete-btn p{
    font-size: 18px;
    font-weight: 600;
}

.large-device .progressbar{
 margin-top: 25px;
}

