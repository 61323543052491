.admin-new-post {
    width: 1000px;
    height: 765px;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 60px;
    padding-bottom: 50px;
    padding-right: 30px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.admin-new-post h2 {
    font-size: 22px;
    padding: 30px 0px 0px 50px;
}

.admin-new-post .admin-new-post-show {
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
    padding-left: 50px;
    margin-top: 30px;
    width: 950px;
    height: 650px;
    overflow: auto;
}

.admin-new-post .admin-new-post-show::-webkit-scrollbar {
    width: 8px;
}

.admin-new-post .admin-new-post-show::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.admin-new-post .admin-new-post-show::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.admin-new-post .admin-new-post-show::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.admin-new-post .admin-new-post-show .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    height: 290px;
    width: 200px;
    margin-right: 30px;
    margin-top: 25px;
}

/* On mouse-over, add a deeper shadow */
.admin-new-post .admin-new-post-show .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.admin-new-post .admin-new-post-show .container {
    padding: 2px 10px;
}

.admin-new-post .admin-new-post-show .container h2{
    margin-left: -45px;
    margin-top: -30px;
}

.admin-new-post .admin-new-post-show .container hr{
    margin-left: 0px;
    width: 98%;
}

.admin-new-post .admin-new-post-show .container h4{
    margin-left: 5px;
}

.admin-new-post .admin-new-post-show .container p{
    margin-left: 5px;
}


/* Add rounded corners to the top left and the top right corner of the image */
.admin-new-post .admin-new-post-show .admin-new-post-img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.admin-new-post .admin-new-post-show .seller-date {
    margin-top: 20px;
}